import React, { Suspense, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch } from 'react-router-dom';
import axios from "axios";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { msalInstance } from "./index";
import { CircularProgress } from "@mui/material";
import { DentsuConsulting } from './components/dccontext/Components/DentsuConsulting';
import { lazyWithRetry } from "./components/utils/Utils";

const PricingIndex = lazyWithRetry(() => import("./components/dcpricingtool/index"));
const SurveyIndex = lazyWithRetry(() => import("./components/dcsurvey/index"));

const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        //User is not signed in. Throw error or wait for user to login. Do not attempt to log a user in outside of the context of MsalProvider
        return null;
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0]
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken
};

//#region axios interceptor for access token
export const axiosGQL = axios.create({
    url: process.env.REACT_APP_AXIOS_URL
});
//#endregion

function App() {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState("");

    useEffect(() => {
        if (accessToken) {
            axiosGQL.interceptors.request.use(
                async (config) => {
                    const accessToken = await acquireAccessToken(msalInstance);
                    if (accessToken) {
                        config.headers["Authorization"] = `Bearer ${accessToken}`;
                        config.headers["Access-Control-Allow-Credentials"] = true;
                    }
                    return config;
                },
                function (error) {
                    return Promise.reject(error);
                }
            );
        }
    }, [accessToken]);
    //checks the cache in browser storage to see if a non-expired access token exists and returns it
    useEffect(() => {
        const accessTokenRequest = {
            scopes: ["User.Read"],
            account: accounts[0],
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 7200
        };
        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    setAccessToken(accessTokenResponse.accessToken);
                })
                .catch((error) => {
                    //if accesstoken not found, redirect for login
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                // Acquire token interactive success
                                setAccessToken(accessTokenResponse.accessToken);
                            });
                    }
                    console.log(error);
                });
        }
    }, [instance, accounts, inProgress]);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
            <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', paddingTop: 100 }}><CircularProgress /></div>}>
                {accessToken && <DentsuConsulting>
                    <Switch>
                        {/*<Route path='/' exact component={Appstore} />*/}
                        {/*<Route path='/testpage' exact component={TestPageHome} />*/}
                        <Route path='/survey' exact component={SurveyIndex} />
                        <Route path='/*' exact component={PricingIndex} />
                        {/*<Route path='/' exact component={HeaderController} />*/}
                    </Switch>
                </DentsuConsulting>
                }
                    
            </Suspense>
        </MsalAuthenticationTemplate>
    );
}

export default App;