import { msalEnvironment } from "../../../authentication/authConfig";
import { axiosGQL } from "../../../App";

//#region UserAccessFlag
export function UserAccessFlagGQL({ userEmail }) {
    return axiosGQL({
        url: msalEnvironment.axiosURL,
        method:"post",
        data: {
            query: `
                query UserEmailCheck ($userEmail: String!){
                  checkByUserEmail_Survey(
                    userEmail: $userEmail
                  )
                }`,
            variables: { userEmail }
        }
    })
};
//#endregion

//#region Fetch Assets
export function AssetsGQL() {
    return axiosGQL({
        url: msalEnvironment.axiosURL,
        method: "post",
        data: {
            query: `
            query Images{
                appImages_AppStore
                }`,
        }
    })
};
//#endregion